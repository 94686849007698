import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import SEO from 'src/helmetSetup';

const Dashboard = () => {
  return (
    <>
      <SEO
        title="Partner Portal Dashboard"
        description="Check Dashboard here"
        type="page"
      />
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6">Welcome to Partner Admin Portal</Typography>
        </CardContent>
      </Card>
    </>
  );
};
export default Dashboard;
