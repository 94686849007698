import { Helmet } from 'react-helmet-async';

export default function SEO({
  title,
  description,
  type
}: {
  title: string;
  description: string;
  type: string;
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        itemProp="image"
        content="src/resources/images/sustain-logo.svg"
      />
      {/* End Facebook tags */}
    </Helmet>
  );
}
