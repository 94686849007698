import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Grid, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createRolesData, PRIMARY_COLOR } from 'src/constants';
import CustomButton from 'src/components/CustomButton';
import { getRoles } from '../PaymentCalculations/PartnerLevelEnrichedData/actions';
import useAxios from 'src/hooks/useAxios';
import { IPartnerRole } from './Interfaces';
import { getAllStates } from './actions';
import { ROUTES } from '../routingConstant';

const StyledSearch = styled(TextField)(() => ({
  ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PRIMARY_COLOR
  },
  ' & .MuiInputLabel-root.Mui-focused': {
    color: PRIMARY_COLOR
  }
}));

const InboxFilters = ({
  isTutorial,
  setSelectedRole,
  setSelectedState,
  selectedState,
  selectedRole,
  setSearchText,
  searchText,
  onFilter
}) => {
  const history = useHistory();
  const [partnerRoles, setPartnerRoles] = useState<Array<IPartnerRole> | []>(
    []
  );
  const [states, setStates] = useState<any>([]);
  const API = useAxios();
  useEffect(() => {
    (async function () {
      const res = await getRoles(API);
      if (res?.data?.dataList) {
        const roles: Array<IPartnerRole> = res.data.dataList;
        setPartnerRoles(roles);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      //TODO: API update
      const res = await getAllStates({ API });
      if (res?.data) {
        setStates(res?.data);
      }
    })();
  }, []);

  const createStatesDropdownData = () => {
    let data = [];
    if (states && states.length) {
      data = states.map((state) => {
        return {
          label: state.geographyName,
          value: state.serverId
        };
      });
    }
    return data;
  };

  const onRoleSelect = (selected) => {
    if (selected.length && selected.find((option) => option.value === 'all')) {
      setSelectedRole(createRolesData(partnerRoles));
    } else {
      setSelectedRole(selected);
    }
  };

  const onStateSelect = (selected) => {
    if (selected.length && selected.find((option) => option.value === 'all')) {
      setSelectedState(createStatesDropdownData());
    } else {
      setSelectedState(selected);
    }
  };

  return (
    <>
      {/* <Grid container spacing={2} alignItems="center">
        <Grid item sm={6} md={4}>
          <MultiSelect
            autoHighlight
            value={selectedRole}
            onChange={(event, selected) => onRoleSelect(selected)}
            options={createRolesData(partnerRoles)}
            label="Roles"
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <MultiSelect
            autoHighlight
            value={selectedState}
            onChange={(event, selected) => onStateSelect(selected)}
            options={createStatesDropdownData()}
            label="States"
          />
        </Grid>
        <Grid item sm={2}>
          <CustomButton
            title={'Filter'}
            buttonType="secondary"
            variant="contained"
            width="100%"
            onClick={onFilter}
          />
        </Grid>
        <Grid item sm={2}>
          <CustomButton
            title={'Reset'}
            buttonType="secondary"
            variant="contained"
            width="100%"
            onClick={() => {
              setSelectedRole([]);
              setSelectedState([]);
            }}
          />
        </Grid>
      </Grid> */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <Grid item sm={6} md={4}>
          <StyledSearch
            label={
              isTutorial
                ? 'Search by Tutorial Title'
                : 'Search by Announcement Title'
            }
            placeholder="Search"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              width: '100%',
              '& div': {
                borderRadius: '12px'
              }
            }}
            inputProps={{
              maxLength: 10
            }}
          />
          {/* {searchText.length > 0 && searchText.length < 10 && (
              <Typography color={'red'}>Please enter valid number</Typography>
            )} */}
        </Grid>
        <Grid item sm={6} md={2} sx={{ marginLeft: 'auto' }}>
          <CustomButton
            title={'Create'}
            buttonType="primary"
            variant="contained"
            width="100%"
            startIcon={<AddCircleOutline />}
            onClick={() =>
              isTutorial
                ? history.push(ROUTES.addTutorial)
                : history.push(ROUTES.addAnnouncements)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InboxFilters;
